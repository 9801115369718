import { ReactMediaRecorder } from "react-media-recorder";
import { useState, lazy, Suspense } from "react";
import Layout from './Layout';
import { VideoCameraIcon } from '@heroicons/react/solid'
import Modal from './Modal';
import { useDeviceSelectors } from 'react-device-detect'

const Ide = lazy(() => import("./Ide"))
const Md = lazy(() => import("./Md"))
const Wb = lazy(() => import("./Wb"))

function App() {

  const tabs = [
    { name: 'Draw', href: '#', current: false },
    { name: 'Code', href: '#', current: false },
    { name: 'Write', href: '#', current: true },
  ]

  const [currentEditor, setCurrentEditor] = useState("Draw")
  const [drawing, setDrawing] = useState(null)
  const [zenMode, setZenMode] = useState(true)
  const [gridMode, setGridMode] = useState(true)
  function onDrawingChange(elements, appState) {
    if (!drawing || drawing.elements !== elements)  {
      setDrawing({
        elements,
        appState,
      })
    }
  }

  const [code, setCode] = useState(null)
  const themes = [ "monokai", "github", ]
  const [theme, setTheme] = useState(themes[0])
  const kbs = ["none", "vim", "emacs", "vscode"]
  const [kb, setKb] = useState(kbs[0])
  const modes = ["ruby", "python", "javascript", "java", "c++", "rust"]
  const [mode, setMode] = useState(modes[0])

  const [md, setMd] = useState('')
  const [selectors, data] = useDeviceSelectors()
  const { isSafari } = selectors

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <Layout title="Studio">



        <div className="mb-6" >
          <div className="flex justify-between">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                defaultValue={currentEditor}
                onChange={(e) => setCurrentEditor(e.target.value)}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="flex space-x-4" aria-label="Recorder and Tabs">
                {
                  !!isSafari
                  ? (
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" 
                      onClick={() => window.alert("Sorry, Safari doesn't support recording yet.") }
                      >
                        <VideoCameraIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Record
                    </button>
                  )
                  : (
                    <ReactMediaRecorder
                      screen
                      render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => (
                        <>
                            <button
                              type="button"
                              onClick={ status === "recording" ? stopRecording : startRecording }
                              className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" >

                              <VideoCameraIcon className={
                                `${
                                  [
                                    status === "recording" ? "animate-pulse text-red-400" : "",
                                  ].join()
                                } -ml-1 mr-2 h-5 w-5 text-gray-400`
                              } aria-hidden="true" />

                              {
                                status === "recording" ? "Stop Recording" : "Record"
                              }

                            </button>

                            {
                              status === "stopped" &&
                                <Modal onClose={clearBlobUrl }>
                                  <video src={mediaBlobUrl} controls autoPlay />
                                </Modal>
                            }
                            </>
                      )} />
                  )
                }


                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.name === currentEditor? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                      'px-3 py-2 font-medium text-sm rounded-md'
                    )}
                    aria-current={tab.name === currentEditor ? 'page' : undefined}
                    onClick={(e) => setCurrentEditor(tab.name)}
                  >
                    {tab.name}
                  </a>
                ))}

              </nav>
            </div>

          </div>
        </div>


        {
          currentEditor === 'Draw' && (
            <div className="w-full h-screen">
              <Suspense fallback={<div>loading...</div>} >
                <Wb data={drawing} onChange={onDrawingChange} {...{ zenMode, setZenMode, gridMode, setGridMode}} />
              </Suspense>
            </div>
          )
        }


        {
          currentEditor === 'Code' && (
            <div className="w-full h-screen">
              <Suspense fallback={<div>loading...</div>} >
                <Ide
                {
                  ...{
                    code, setCode,
                    themes, theme, setTheme,
                    kbs, kb, setKb,
                    modes, mode, setMode
                  }
                }
                />
              </Suspense>
            </div>
          )
        }

        {
          currentEditor === 'Write' && (
            <div className="w-full h-screen">
              <Suspense fallback={<div>loading...</div>}>
                <Md data={md} onChange={setMd} />
              </Suspense>
            </div>
          )
        }

      </Layout>
    </>

  );
}

export default App;
